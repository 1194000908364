<template>
  <Card title="Acessos mês" :subtitle="subtitle">
    <Loading v-if="loading" />
    <div v-else-if="!rows.length" class="text-center">Sem ocorrências</div>
    <Table v-else>
      <HeaderRow>
        <HeaderCell width="50%">Local</HeaderCell>
        <HeaderCell width="auto" align="right">Entradas (un)</HeaderCell>
        <HeaderCell width="auto" align="right">Entradas</HeaderCell>
      </HeaderRow>
      <tr v-for="(row, i) of rows" :key="i">
        <td class="w-50">{{ row.place_name || '-' }}</td>
        <td class="w-25 font-weight-bolder text-right">{{ row.unique }}</td>
        <td class="w-25 font-weight-bolder text-right">{{ row.qty }}</td>
      </tr>
      <tr>
        <td class="w-50">Total</td>
        <td class="w-25 font-weight-bolder text-right">{{ summary.unique_sum }}</td>
        <td class="w-25 font-weight-bolder text-right">{{ summary.qty_sum }}</td>
      </tr>
    </Table>

    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
      <i class="la la-ellipsis-v ml-1" @click="openFilter"></i>
    </template>
  </Card>
</template>

<script>
import Card from '../Card'
import Table from '../Table'
import moment from 'moment'
import { H } from 'hennig-common'
import Loading from '@/components/Loading'
import HeaderCell from '@/components/Table/HeaderCell'
import HeaderRow from '@/components/Table/HeaderRow'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'

export default {
  name: 'AccessLastMonth',
  components: {
    HeaderRow,
    HeaderCell,
    Loading,
    Table,
    Card
  },
  props: { },
  data () {
    return {
      loading: false,
      search: {
        year: '',
        month: ''
      },
      summary: {
        unique_sum: 0,
        qty_sum: 0
      },
      rows: []
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id']),
    subtitle () {
      if (this.search.month && this.search.year) {
        return `${this.search.year}/${this.search.month}`
      }

      return 'Mês atual'
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    openFilter () {
      this.dialogs().MonthDialog.show(params => {
        if (!params) return
        this.search.year = params.year
        this.search.month = params.month
        this.load()
      })
    },
    load () {
      this.loading = true
      H.rpc('DashAccess', 'accessThisMonth', [{ event_id: this.event_id, ...this.search }], (r) => {
        this.rows = r.rows || []
        this.summary.unique_sum = r.unique_sum || 0
        this.summary.qty_sum = r.qty_sum || 0
        this.loading = false
      })
    }
  }
}
</script>
