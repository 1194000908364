<template>
  <Card title="Acessos autorizados" subtitle="Últimas ocorrências">
    <Loading v-if="loading" />
    <div v-else-if="!rows.length" class="text-center">Sem ocorrências</div>
    <Table v-else>
      <HeaderRow>
        <HeaderCell width="auto">Tipo</HeaderCell>
        <HeaderCell width="auto">Código</HeaderCell>
        <HeaderCell width="auto" align="right">Último acesso</HeaderCell>
      </HeaderRow>
      <tr v-for="(row, i) of rows" :key="i">
        <td class="w-25">
          <template v-if="row.type === 'enter'">
            <i class="la la-sign-in-alt"></i> <span>Entrada</span>
          </template>
          <template v-else-if="row.type === 'exit'">
            <i  class="la la-sign-out-alt"></i> <span>Saída</span>
          </template>
          <template v-else-if="row.type === 'validated'">
            <i  class="la la-dot-circle"></i> <span>Validação</span>
          </template>
        </td>
        <td class="w-25">{{ row.code }}</td>
        <td class="text-right" :title="formatFrom(row.created_at || row.at)" >{{ formatAt(row.created_at || row.at) }}</td>
      </tr>
    </Table>

    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
      <!--      <i class="la la-ellipsis-v ml-1" @click="openFilter"></i>-->
    </template>
  </Card>
</template>

<script>
import Card from '../Card'
import Table from '../Table'
import moment from 'moment'
import { H } from 'hennig-common'
import Loading from '@/components/Loading'
import HeaderRow from '@/components/Table/HeaderRow'
import HeaderCell from '@/components/Table/HeaderCell'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'

export default {
  name: 'AccessLastAllowedList',
  components: {
    HeaderCell,
    HeaderRow,
    Loading,
    Table,
    Card
  },
  props: {},
  data () {
    return {
      loading: false,
      rows: []
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id'])
  },
  mounted () {
    // this.timer = setInterval(() => {
    //   this.now = moment().toJSON()
    // }, 10000)

    this.load()
  },
  beforeDestroy () {
    // clearInterval(this.timer)
  },
  methods: {
    formatFrom (v) {
      return moment(v).fromNow(true)
    },
    formatAt (v) {
      return moment(v).format('L LT')
    },
    update (data) {
      if (!data.access_id) {
        this.rows.unshift(data)
        return
      }

      for (const index in this.rows) {
        if (!this.rows.hasOwnProperty(index)) continue
        const row = this.rows[index]
        if (row.access_id === data.access_id) {
          const [removed] = this.rows.splice(index, 1)
          removed.next = data.next
          removed.at = data.at
          this.rows.unshift(removed)
          break
        }
      }
    },
    load () {
      this.loading = true
      H.rpc('DashAccess', 'lastAccess', [{ event_id: this.event_id }], (r) => {
        this.rows = (r || [])
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.scroll-styled {
  height: 100%;
  overflow-y: scroll;
}
</style>
