<script>
import { Doughnut } from 'vue-chartjs'
import { H } from 'hennig-common'

export default {
  name: 'TicketTypePie',
  extends: Doughnut,
  data: () => (
    {
      chartdata: {
        datasets: [
          {
            data: [
              10,
              20,
              30,
              30
            ],
            backgroundColor: [
              '#9ea04f',
              '#0fb857',
              '#0d30c4',
              '#ad3333'
            ]
          }
        ],
        labels: [
          'VIP',
          'Cortesia',
          'Geral',
          'Socio'
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Tipos de ingresso'
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ),
  mounted () {
    // H.rpc('Dashboard', 'groupTicketsData', [], (r) => {
    //     this.chartdata.datasets[0].data = r
    //     this.renderChart(this.chartdata, this.options)
    // })

    this.renderChart(this.chartdata, this.options)
  }
}
</script>
