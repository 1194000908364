<template>
  <Card
    title="Total de Acessos"
    subtitle="Hoje Entrada / Saída"
    :style="getCardStyle"
  >
    <div class="info-text mt-3">
      <div class="large">{{ enter }}</div>
      /
      <div class="small">{{ exit }}</div>
    </div>

    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
      <div class="dropdown">
        <i class="la la-ellipsis-v link" data-toggle="dropdown" aria-expanded="false"></i>
        <div class="dropdown-menu">
          <div class="dropdown-item">
            <span>Fundo</span><input type="color" v-model.lazy="pref.bg"/>
          </div>
          <div class="dropdown-item">
            <span>Texto</span><input type="color" v-model.lazy="pref.text"/>
          </div>
          <div class="dropdown-item">
            <span>Sombra do texto</span><input type="color" v-model.lazy="pref.shadow"/>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card'
import { H, setObj } from 'hennig-common'
import { showInfo } from '@/notifications'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'

export default {
  name: 'AccessSmallCounter',
  components: { Card },
  data () {
    return {
      enter: 0,
      exit: 0,
      pref: {
        bg: '#ffffff',
        text: '#9970d7',
        shadow: '#000000'
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id']),
    getCardStyle () {
      return {
        '--bg': this.pref.bg,
        '--text': this.pref.text,
        '--shadow': this.pref.shadow
      }
    }
  },
  mounted () {
    this.loadPref()
    this.load()
    this.timer = setInterval(this.load, 60 * 10000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.watching && this.watching()
  },
  methods: {
    installWatch () {
      this.watching && this.watching()
      this.watching = this.$watch('pref', (v) => this.savePref(v), { deep: true })
    },
    loadPref () {
      H.rpc('User', 'preferences', [], (r) => {
        setObj(this, this.pref, r.AccessSmallCounter || {})
        this.installWatch()
      })
    },
    savePref (v) {
      H.rpc('User', 'preferences', [{ AccessSmallCounter: v }], (r) => {
        showInfo('A preferência foi salva')
      })
    },
    load () {
      H.rpc('DashAccess', 'accessCountToday', [], (r) => {
        if (r) {
          this.enter = r.enter
          this.exit = r.exit
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  ::v-deep .card-body {
    background-color: var(--bg);
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .info-text {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 1rem;
    color: var(--text);
    text-shadow: 1px 1px 0 var(--shadow);

    .large {
      font-size: 60px;
    }

    .small {
      font-size: 18px;
    }
  }
}
</style>
