<template>
  <div class="container">
    <Card title="Dashboards" subtitle="Listagem de combinações variadas de dashboard">

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <EditHeader/>
            <DeleteHeader/>
            <StatusHeader/>
            <th data-column-id="name" data-sortable="true">Nome</th>
          </tr>
        </thead>
      </table>

    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'

export default {
  name: 'DashManager',
  components: {
    Card,
    StatusHeader,
    DeleteHeader,
    EditHeader,
    PkHeader
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const addLabel = 'Novo'
      const collectionObj = 'DashManager'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        addLabel
      })
    }
  }
}
</script>
