<template>
  <div class="Table scroll-styled" :class="{ fitWindow }" :style="{'--size_diff': size_diff }">
    <div class="loading-container" v-if="loading">
      <Loading/>
      <div>Aguarde procurando ...</div>
    </div>
    <div class="empty-container" v-else-if="initial">
      <slot name="initial">
        <div>Nenhum registro para exibir</div>
      </slot>
    </div>
    <div class="empty-container" v-else-if="empty">
      <div>Nenhum registro encontrado</div>
    </div>
    <table class="table table-striped table-bordered m-0" :class="[`table-${size}`]" v-else>
      <slot></slot>
    </table>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

function isOverflown () {
  const element = document.querySelector('html')
  return element.scrollHeight > element.clientHeight
}

export default {
  name: 'Table',
  components: {
    Loading
  },
  props: {
    size: {
      String,
      default: 'sm'
    },
    loading: {
      Boolean,
      default: false
    },
    empty: {
      Boolean,
      default: false
    },
    initial: {
      Boolean,
      default: false
    },
    fitWindow: {
      Boolean,
      default: false
    }
  },
  data () {
    return {
      size_diff: ''
    }
  },
  mounted () {
    this.fitToPage()
  },
  methods: {
    async fitToPage () {
      let start = this.$el.getBoundingClientRect().top
      this.size_diff = start + 'px'
      await this.$nextTick()
      while (isOverflown()) {
        this.size_diff = (start += 2) + 'px'
        await this.$nextTick()
        if (start > 1000) break
      }
    },
    rowsPerPage () {
      return (Math.floor(this.$el.getBoundingClientRect().height / 46) - 2) || 20
    }
  }
}
</script>

<style lang="scss" scoped>
.Table {
  overflow: auto;
  &.fitWindow {
    height: calc(100vh - var(--size_diff, 400px));
  }
  //border: 1px solid rgba(0, 0, 0, 0.25);

  .empty-container, .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
  }

  .table {
    //width: 150%;
    table-layout: fixed;
    height: 1px; // Faz com que o td possa ter 100% de altura
    //border: none;
  }

  ::v-deep {
    //.table > :not(caption) > * > * {
    //  padding: 0 !important;
    //}

    tr:hover {
      td {
        background-color: var(--primary-10);
      }
    }

    .table-inner {
      tr {
        td, th {
          border-bottom: 1px solid rgb(222, 226, 230);
        }
      }
    }
  }
}
</style>
