<template>
  <Card class="OrdersByEventTotalNumbers" title="Total Vendas">
    <div class="d-flex flex-column align-items-center gap-sm mb-5">
      <small>Total de vendas</small><b class="fs-larger">{{ data.regular }}</b>
    </div>
    <div class="d-flex flex-column align-items-center gap-sm">
      <small>Vendas meia entrada</small><b class="fs-larger">{{ data.half }}</b>
    </div>
    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
    </template>
  </Card>
</template>
<script>
import Card from '@/components/Card.vue'
import { mapActions, mapState } from 'pinia'
import { useDash } from '@/stores/dash'
import { formatNumber } from '@/common'

export default {
  name: 'OrdersByEventTotalNumbers',
  components: {
    Card
  },
  computed: {
    ...mapState(useDash, { data: 'ordersByEventTotalNumbersData' })
  },
  watch: {
    orders_event (rows) {
      this.value = rows.length ? rows[0].aggregate : 0
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    ...mapActions(useDash, { load: 'ordersByEventTotalNumbers' }),
    formatNumber
  }
}
</script>
<style lang="scss" scoped>
.OrdersByEventTotalNumbers {
  .fs-larger {
    font-size: 2rem;
  }
}
</style>
