<template>
  <tr><slot></slot></tr>
</template>

<script>
export default {
  name: 'HeaderRow'
}
</script>

<style lang="scss" scoped>
tr {
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
</style>
