<template>
  <div class="container-fluid">
    <MasonryWall :items="items" :column-width="400" :gap="16">
      <template #default="{ item }">
        <Component :is="item"/>
      </template>
    </MasonryWall>
  </div>
</template>

<script>
import MasonryWall from '@yeger/vue2-masonry-wall'
import Card from '@/components/Card'
import AccessLastAllowedList from '@/components/dash/AccessLastAllowedList'
import AccessLastMonth from '@/components/dash/AccessLastMonth'
import AccessPerHourLineAccessPerHourLine from '@/components/dash/AccessPerHourLine'
import AccessListPie from '@/components/dash/AccessListPie'
import AccessCodeTypeDaily from '@/components/dash/AccessCodeTypeDaily'
import AccessSmallCounter from '@/components/dash/AccessSmallCounter'
import OrdersByEventAvgBar from '@/components/dash/OrdersByEventAvgBar.vue'
import OrdersByEventBar from '@/components/dash/OrdersByEventBar.vue'
import OrdersPerQuarterMinuteLine from '@/components/dash/OrdersPerQuarterMinuteLine.vue'
import OrdersByEventAvgNumbers from '@/components/dash/OrdersByEventAvgNumbers.vue'
import OrdersByEventProgressBar from '@/components/dash/OrdersByEventProgressBar.vue'
import OrdersByEventTotalNumbers from '@/components/dash/OrdersByEventTotalNumbers.vue'
import AccessPerMinuteLine from '@/components/dash/AccessPerMinuteLine.vue'
import AccessLastDeniedList from '@/components/dash/AccessLastDeniedList.vue'
import TicketTypePie from '@/components/dash/TicketTypePie.vue'
import { mapActions, mapState } from 'pinia'
import { useDash } from '@/stores/dash'

export default {
  name: 'Dash',
  components: {
    MasonryWall,
    Card,
    AccessLastAllowedList,
    AccessLastMonth,
    AccessPerHourLineAccessPerHourLine,
    AccessListPie,
    AccessCodeTypeDaily,
    AccessSmallCounter,
    OrdersByEventAvgBar,
    OrdersByEventBar,
    OrdersPerQuarterMinuteLine,
    OrdersByEventAvgNumbers,
    OrdersByEventProgressBar,
    OrdersByEventTotalNumbers,
    AccessPerMinuteLine,
    AccessLastDeniedList,
    TicketTypePie
  },
  data () {
    return {
      items: []
    }
  },
  computed: {
    ...mapState(useDash, ['loadDashboardComponentsData'])
  },
  watch: {
    'loadDashboardComponentsData.items' (data) {
      this.items = [...data[this.$route.params.dash_id]]
    }
  },
  mounted () {
    if (this.$route.params.dash_id) {
      this.load(this.$route.params.dash_id)
    }
  },
  methods: {
    ...mapActions(useDash, { load: 'loadDashboardComponents' })
  }
}
</script>

<style scoped lang="scss">
.chart-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  > * {
    margin: 0;
    width: 600px;
    height: 500px;

    &.small {
      width: 300px;
      height: 250px;
      background-color: white;
    }
  }
}
</style>
