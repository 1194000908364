<template>
  <Card class="OrdersByEventProgressBar" title="Tickets vendidos">
    <div class="d-flex justify-content-center flex-column gap h-100">
      <div class="custom-progress-bar">
        <div class="value">
          {{ sold }}
        </div>
      </div>

      <div v-if="capacity" class="text-center">
        Capacidade total - {{ capacity }}
      </div>
    </div>
    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
    </template>
  </Card>
</template>
<script>
import Card from '@/components/Card.vue'
import { mapActions, mapState } from 'pinia'
import { useDash } from '@/stores/dash'
import { formatNumber } from '@/common'

export default {
  name: 'OrdersByEventProgressBar',
  components: {
    Card
  },
  data () {
    return {
      capacity: 0,
      sold: 0
    }
  },
  computed: {
    ...mapState(useDash, ['orders_event_progress'])
  },
  watch: {
    orders_event_progress (data) {
      this.capacity = data.capacity || 0
      this.sold = data.sold || 0
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    ...mapActions(useDash, { load: 'ordersByEventProgress' }),
    formatNumber
  }
}
</script>
<style lang="scss" scoped>
.OrdersByEventProgressBar {
  height: 200px;

  .custom-progress-bar {
    width: 100%;
    background-color: var(--primary-10);

    .value {
      height: 1.75rem;
      line-height: 1.75rem;
      width: 75%;
      background-color: var(--primary);
      color: #ffffff;
    }
  }
}
</style>
