<template>
  <Card title="Acessos por hora" :subtitle="subtitle">
    <Chart ref="Chart"/>

    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
      <i class="la la-ellipsis-v ml-1" @click="openFilter"></i>
    </template>
  </Card>
</template>
<script>
import { Line } from 'vue-chartjs'
import Card from '../Card'
import { H } from 'hennig-common'
import moment from 'moment'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'

const Chart = {
  extends: Line,
  data () {
    return ({
      chartdata: {
        datasets: [
          {
            label: 'Entradas',
            backgroundColor: 'green',
            data: []
          }
        ],
        labels: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        }
      }
    })
  },
  methods: {
    load () {
      this.renderChart(this.chartdata, this.options)
    }
  }
}

export default {
  name: 'AccessPerHourLine',
  components: {
    Card,
    Chart
  },
  props: { },
  data () {
    return {
      search: {
        date: ''
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id']),
    subtitle () {
      if (this.search.date) {
        return moment(this.search.date).format('L')
      }

      return 'Hoje'
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    openFilter () {
      this.dialogs().DayDialog.show(params => {
        if (!params) return
        this.search.date = params.date
        this.load()
      })
    },
    load () {
      H.rpc('DashAccess', 'accessDailyByHour', [{ event_id: this.event_id, ...this.search }], (r) => {
        if (!r) return
        const freeze = Object.freeze(r)
        this.$refs.Chart.chartdata.datasets[0].data = freeze.data
        this.$refs.Chart.chartdata.labels = freeze.labels
        this.$refs.Chart.load()
      })
    }
  }
}
</script>
