<template>
  <Card title="Vendas" subtitle="15 minutos">
    <Chart ref="Chart" :with="400" :height="200"/>
    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
    </template>
  </Card>
</template>
<script>
import { Line } from 'vue-chartjs'
import { H } from 'hennig-common'
import moment from 'moment'
import Card from '@/components/Card.vue'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'

const Chart = {
  extends: Line,
  data () {
    return {
      chartdata: {
        datasets: [],
        labels: []
      },
      options: {
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: ''
        },
        scales: {
          xAxes: [{
            gridLines: { display: false }
          }],
          yAxes: [{
            gridLines: { display: false },
            ticks: {
              // Formatar eixo
              callback: function (value, index, values) {
                return ''
                // return '$' + value
              }
            }
          }]
        }
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id'])
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      const status = {
        new: {
          label: 'Pendente',
          color: '#ffe262'
        },
        cancelled: {
          label: 'Cancelado',
          color: '#ff5e7a'
        },
        paid: {
          label: 'Pago',
          color: '#3f45b6'
        }
      }

      H.rpc('Dash', 'ordersPerQuarterMinuteLine', [], (groups) => {
        if (!groups) return
        this.chartdata.datasets.splice(0)
        for (const group in groups) {
          this.chartdata.labels = Object.keys(groups[group]).map(v => moment(v).format('DD/MMM LT'))
          this.chartdata.datasets.push({
            label: status[group].label,
            fill: false,
            borderColor: status[group].color,
            backgroundColor: status[group].color,
            data: Object.values(groups[group])
          })
        }

        this.renderChart(this.chartdata, this.options)
      })
    }
  }
}

export default {
  name: 'OrdersPerQuarterMinuteLine',
  components: {
    Card,
    Chart
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.$refs.Chart.load()
    }
  }
}
</script>
