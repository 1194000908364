<template>
  <Card title="Tipo de leitura" :subtitle="subtitle">
    <Chart ref="Chart"/>

    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
      <i class="la la-ellipsis-v ml-1" @click="openFilter"></i>
    </template>
  </Card>
</template>
<script>
import { Doughnut } from 'vue-chartjs'
import Card from '../Card'
import moment from 'moment'
import { H } from 'hennig-common'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'

const Chart = {
  extends: Doughnut,
  data () {
    return ({
      chartdata: {
        datasets: [
          {
            data: [],
            backgroundColor: [
              '#00afff',
              '#85a7ff',
              '#c59df8',
              '#f393df',
              '#ff90be',
              '#ff969a',
              '#ffa67b',
              '#ffb966'
            ]
          }
        ],
        labels: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        }
      }
    })
  },
  methods: {
    load () {
      this.renderChart(this.chartdata, this.options)
    }
  }
}

export default {
  name: 'AccessCodeTypeDaily',
  components: {
    Card,
    Chart
  },
  props: {},
  data () {
    return {
      search: {
        date: ''
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id']),
    subtitle () {
      if (this.search.date) {
        return moment(this.search.date).format('L')
      }

      return 'Hoje'
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    openFilter () {
      this.dialogs().DayDialog.show(params => {
        if (!params) return
        this.search.date = params.date
        this.load()
      })
    },
    load () {
      H.rpc('DashAccess', 'codeTypeDaily', [{ event_id: this.event_id, ...this.search }], (r) => {
        if (!r) return
        const freeze = Object.freeze(r)
        this.$refs.Chart.chartdata.datasets[0].data = freeze.data
        this.$refs.Chart.chartdata.labels = freeze.labels
        this.$refs.Chart.load()
      })
    }
  }
}
</script>
