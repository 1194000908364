<template>
  <Card class="OrdersByEventAvgNumbers" title="Ticket médio - R$">
    <div class="d-flex align-items-center h-100">
      <div class="d-flex align-items-baseline justify-content-center gap w-100">
        <div class="text-small">R$</div>
        <div class="text-large">{{ formatNumber(value) }}</div>
      </div>
    </div>
    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
    </template>
  </Card>
</template>
<script>
import Card from '@/components/Card.vue'
import { mapActions, mapState } from 'pinia'
import { useDash } from '@/stores/dash'
import { formatNumber } from '@/common'

export default {
  name: 'OrdersByEventAvgNumbers',
  components: {
    Card
  },
  data () {
    return {
      value: 0
    }
  },
  computed: {
    ...mapState(useDash, ['orders_event'])
  },
  watch: {
    orders_event (rows) {
      this.value = rows.length ? rows[0].aggregate : 0
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    ...mapActions(useDash, { load: 'ordersByEventAvgBar' }),
    formatNumber
  }
}
</script>
<style lang="scss" scoped>
.OrdersByEventAvgNumbers {
  height: 200px;

  .text-small {
    font-size: 1.25rem;
    color: var(--primary);
  }

  .text-large {
    font-size: 2.25rem;
    color: var(--primary);
  }
}
</style>
