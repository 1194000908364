<template>
  <Card title="Acessos por minuto" subtitle="...">
    <Chart/>
  </Card>
</template>
<script>
import { Line } from 'vue-chartjs'
import Card from '../Card'

const Chart = {
  extends: Line,
  data: () => ({
    chartdata: {
      datasets: [
        {
          label: 'Entradas',
          backgroundColor: 'green',
          data: [
            0,
            10,
            5,
            20
          ]
        }
      ],
      labels: [
        '10 min',
        '20 min',
        '30 min',
        '40 min'
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false
      }
    }
  }),
  mounted () {
    // H.rpc('Dashboard', 'myTicketsData', [], (r) => {
    //     this.chartdata.datasets[0].data = r
    // })

    this.renderChart(this.chartdata, this.options)
  }
}

export default {
  name: 'AccessPerMinuteLine',
  components: { Card, Chart }

}
</script>
