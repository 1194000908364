<template>
  <th :style="theStyle">
    <div class="d-flex align-items-center" :class="theClass">
      <slot>&nbsp;</slot>
    </div>
  </th>
</template>

<script>
export default {
  name: 'HeaderCell',
  inheritAttrs: false,
  props: {
    width: {},
    align: {}
  },
  computed: {
    theStyle () {
      return { width: this.width }
    },
    theClass () {
      if (this.align === 'right') {
        return ['justify-content-end']
      }

      if (this.align === 'center') {
        return ['justify-content-center']
      }

      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  padding: 0;
  border: none;

  > div {
    height: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-top-width: 1px;
    border-bottom-width: 1px;
    padding: .75rem;
  }

  &:first-of-type {
    > div {
      border-left: none;
    }
  }

  &:last-of-type {
    > div {
      border-right: none;
    }
  }
}
</style>
