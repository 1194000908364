<template>
  <Card title="Acessos negados" subtitle="Últimas ocorrências">
    <Loading v-if="loading" />
    <div v-else-if="!rows.length" class="text-center">Sem ocorrências</div>
    <Table v-else>
      <tr v-for="(row, i) of rows" :key="i">
        <td class="">{{ row.device_name }}</td>
        <td class="text-danger">
          <span v-if="row.qty > 1">({{ row.qty }}) </span>
          <span>{{ row.failed_message }}</span>
        </td>
        <td class="text-right text-muted">{{ formatAt(row.at) }}</td>
      </tr>
    </Table>
  </Card>
</template>

<script>
import Card from '../Card'
import Table from '../Table'
import moment from 'moment'
import Loading from '@/components/Loading'

export default {
  name: 'AccessLastDeniedList',
  components: { Loading, Table, Card },
  props: { },
  data () {
    return {
      loading: false,
      now: moment().toJSON(),
      rows: []
    }
  },
  watch: {
    // event_id (v) {
    //   if (!v) return
    //   window.echoInstance && window.echoInstance
    //     .channel(`Kernel.${v}`)
    //     .listen('Device', data => {
    //       if (data.type === 'failed') {
    //         if (this.rows.length) {
    //           if (this.rows[0].device_name === data.device_name && this.rows[0].failed_message === data.failed_message) {
    //             this.rows[0].qty++
    //             this.rows[0].at = data.at
    //             return
    //           }
    //         }
    //
    //         this.rows.unshift({
    //           ...data,
    //           qty: 1,
    //           text: 'Negado',
    //           next: data.device_name
    //         })
    //       }
    //     })
    // }
  },
  mounted () {
    this.timer = setInterval(() => {
      this.now = moment().toJSON()
    }, 10000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    formatAt (v) {
      return moment(v).from(this.now, true)
    }
  }
}
</script>
