<template>
  <Card title="Vendas por evento">
    <Chart ref="Chart" :with="400" :height="200"/>
    <template #header_buttons>
      <i class="la la-refresh link " @click="load"></i>
    </template>
  </Card>
</template>
<script>
import { Bar } from 'vue-chartjs'
import { H } from 'hennig-common'
import Card from '@/components/Card.vue'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'

const Chart = {
  extends: Bar,
  data () {
    return {
      chartdata: {
        datasets: [],
        labels: ['']
      },
      options: {
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: ''
        },
        scales: {
          xAxes: [{
            gridLines: { display: false }
          }],
          yAxes: [{
            gridLines: { display: false },
            ticks: {
              // Formatar eixo
              callback: function (value, index, values) {
                return ''
                // return '$' + value
              }
            }
          }]
        }
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id'])
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      H.rpc('Dash', 'ordersByEventQtyBar', [], (rows) => {
        if (!rows) return
        this.chartdata.datasets.splice(0)
        for (const row of rows) {
          this.chartdata.datasets.push({
            label: row.event.name,
            borderWidth: 1,
            // borderColor: status[group].color,
            backgroundColor: '#3f45b6',
            data: [row.aggregate]
          })
        }

        this.renderChart(this.chartdata, this.options)
      })
    }
  }
}

export default {
  name: 'OrdersByEventBar',
  components: {
    Card,
    Chart
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.$refs.Chart.load()
    }
  }
}
</script>
